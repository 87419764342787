// PRODUCT V3
import { Splide } from '@splidejs/splide';

// Desktop Product Gallery Component
class DesktopProductGallery extends HTMLElement {
  constructor() {
    super();
    this.modal = document.querySelector('#desktop-zoom-modal');
    this.modalContent = this.modal?.querySelector('#modal-content');
    this.closeButton = this.modal?.querySelector('.desktop-zoom-close');
    this.galleryItems = this.querySelectorAll('.desktop-gallery-item');

    // Store bound event handlers for cleanup
    this.boundCloseModal = this.closeModal.bind(this);
    this.boundHandlers = new Map(); // Store handlers by element
  }

  connectedCallback() {
    if (!this.modal || !this.modalContent) return;
    this.setupZoomHandlers();
    this.setupModalHandlers();
  }

  disconnectedCallback() {
    // Clean up modal event listeners
    this.closeButton?.removeEventListener('click', this.boundCloseModal);

    // Clean up all item event listeners
    this.galleryItems.forEach((item) => {
      const media = item.querySelector('img, video');
      if (!media) return;

      // Get the stored handlers for this item
      const handlers = this.boundHandlers.get(item);
      if (handlers) {
        item.removeEventListener('click', handlers.click);
        if (media.tagName === 'IMG') {
          item.removeEventListener('mousemove', handlers.mouseMove);
          item.removeEventListener('mouseleave', handlers.mouseLeave);
        }
      }
    });

    // Clear the handlers map
    this.boundHandlers.clear();
  }

  setupZoomHandlers() {
    this.galleryItems.forEach((item) => {
      const media = item.querySelector('img, video');
      if (!media) return;

      // Create bound handlers
      const clickHandler = () => this.openModal(media);
      const mouseMoveHandler = (e) => this.handleMouseMove(e, media);
      const mouseLeaveHandler = () => this.handleMouseLeave(media);

      // Store handlers for cleanup
      this.boundHandlers.set(item, {
        click: clickHandler,
        mouseMove: mouseMoveHandler,
        mouseLeave: mouseLeaveHandler
      });

      item.addEventListener('click', clickHandler);

      if (media.tagName === 'IMG') {
        item.addEventListener('mousemove', mouseMoveHandler);
        item.addEventListener('mouseleave', mouseLeaveHandler);
      }
    });
  }

  setupModalHandlers() {
    this.closeButton?.addEventListener('click', this.boundCloseModal);
  }

  closeModal() {
    this.modal.classList.add('hidden');
    this.modal.classList.remove('z-50');
  }

  openModal(media) {
    if (!media || !this.modalContent) return;

    this.modalContent.innerHTML = '';

    if (media.tagName === 'IMG') {
      const img = document.createElement('img');
      img.src = media.src;
      img.srcset = media.srcset;
      img.className = 'w-full h-full object-cover';
      this.modalContent.appendChild(img);
    } else if (media.tagName === 'VIDEO') {
      const videoClone = document.createElement('video');
      Array.from(media.attributes).forEach((attr) => {
        videoClone.setAttribute(attr.name, attr.value);
      });
      media.querySelectorAll('source').forEach((source) => {
        videoClone.appendChild(source.cloneNode(true));
      });
      videoClone.className = 'w-full h-auto max-h-screen object-contain';
      videoClone.muted = true;
      videoClone.play();
      this.modalContent.appendChild(videoClone);
    }

    this.modal.classList.remove('hidden');
    this.modal.classList.add('z-50');
  }

  handleMouseMove(event, img) {
    if (img.tagName !== 'IMG') return;

    const rect = img.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;
    const percentX = (mouseX / rect.width) * 100;
    const percentY = (mouseY / rect.height) * 100;

    img.style.transformOrigin = `${percentX}% ${percentY}%`;
    img.style.transform = 'scale(2)';
  }

  handleMouseLeave(img) {
    if (img.tagName !== 'IMG') return;

    img.style.transform = '';
    img.style.transformOrigin = '';
  }
}

// Mobile Product Gallery Component
class MobileProductGallery extends HTMLElement {
  constructor() {
    super();
    this.mainCarousel = this.querySelector('#mobile-product-carousel');
    this.zoomCarousel = document.querySelector('#mobile-zoom-carousel');
    this.zoomContainer = document.querySelector('.mobile-zoom-container');
    this.zoomCloseButton = document.querySelector('.mobile-zoom-close');

    // Store splide instances for cleanup
    this.mainSplide = null;
    this.zoomSplide = null;

    // Store bound handlers for cleanup
    this.boundZoomOpen = () => this.openZoom();
    this.boundZoomClose = () => this.closeZoom();
    this.slideHandlers = new Map();
  }

  connectedCallback() {
    this.initializeCarousels();
    this.setupZoomHandlers();
  }

  disconnectedCallback() {
    // Destroy Splide instances
    if (this.mainSplide) {
      this.mainSplide.destroy();
      this.mainSplide = null;
    }

    if (this.zoomSplide) {
      this.zoomSplide.destroy();
      this.zoomSplide = null;
    }

    // Remove zoom event listeners
    const slides = this.querySelectorAll('.splide__slide');
    slides.forEach((slide) => {
      const handler = this.slideHandlers.get(slide);
      if (handler) {
        slide.removeEventListener('click', handler);
      }
    });

    this.zoomCloseButton?.removeEventListener('click', this.boundZoomClose);

    // Clear handler references
    this.slideHandlers.clear();
  }

  initializeCarousels() {
    if (!this.mainCarousel) return;

    // Main carousel
    this.mainSplide = new Splide(this.mainCarousel, {
      pagination: true,
      arrows: false,
      mediaQuery: 'min',
      breakpoints: {
        1024: { destroy: true }
      }
    });

    // Zoom carousel
    if (this.zoomCarousel) {
      this.zoomSplide = new Splide(this.zoomCarousel, {
        pagination: false,
        arrows: true,
        isNavigation: true,
        arrowPath: 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        mediaQuery: 'min',
        breakpoints: {
          1024: { destroy: true }
        }
      });

      this.mainSplide.sync(this.zoomSplide);
      this.zoomSplide.mount();
    }

    this.mainSplide.mount();
  }

  setupZoomHandlers() {
    if (!this.zoomContainer || !this.zoomCloseButton) return;

    const slides = this.querySelectorAll('.splide__slide');
    slides.forEach((slide) => {
      const handler = () => this.openZoom();
      this.slideHandlers.set(slide, handler);
      slide.addEventListener('click', handler);
    });

    this.zoomCloseButton.addEventListener('click', this.boundZoomClose);
  }

  openZoom() {
    this.zoomContainer.classList.remove('max-h-0');
  }

  closeZoom() {
    this.zoomContainer.classList.add('max-h-0');
  }
}

// Register custom elements
customElements.define('desktop-product-gallery', DesktopProductGallery);
customElements.define('mobile-product-gallery', MobileProductGallery);
